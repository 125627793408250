import styled, { css } from 'styled-components';
import {
  AccordionStyles,
  Button,
  ButtonStyles,
  Icon,
  Size,
  desktopHoverCondition,
  mobileScreen,
  pxToRem,
} from '@pointdotcom/pds';
import { HrStyle } from 'components/HrVr/styles';

export const ReadOnlyCheckboxStyle = styled.span.attrs({
  className: 'ReadOnlyCheckboxStyle',
  'aria-hidden': 'true',
})`
  display: block;
  box-sizing: border-box;
  width: ${pxToRem(12)};
  height: ${pxToRem(12)};
  border: 2px solid ${({ theme }) => theme.line.color.default};
`;

export const RowIconStyle = styled(Icon).attrs({
  className: 'RowIconStyle',
})`
  display: block;
  width: var(--rowIconSize);
  height: var(--rowIconSize);
  flex-shrink: 0;
  path {
    fill: ${({ theme }) => theme.line.color.default};
  }
  ${mobileScreen} {
    --rowIconSize: 18px;
  }
`;

export const ReadOnlyCheckboxCheckedStyle = styled(ReadOnlyCheckboxStyle).attrs({
  className: 'ReadOnlyCheckboxCheckedStyle',
})`
  background-color: ${({ theme }) => theme.Color.PointBlue};
  display: flex;
  border: none;
  &::before {
    display: block;
    flex: 1;
    content: '';
    background-image: url('data:image/svg+xml,<svg width="8" height="7" viewBox="0 0 8 7" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M0.741211 2.70368L3.11158 5.07405L7.25973 0.925903" stroke="white" stroke-width="1.4"/></svg>');
    background-repeat: no-repeat;
    background-position: center;
  }
`;

export const DashboardTaskTitleStyle = styled.span.attrs({ className: 'DashboardTaskTitleStyle' })`
  display: block;
`;

export const DashboardTaskContentRowStyle = styled.span.attrs({
  className: 'DashboardTaskContentRowStyle',
})`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: var(--taskHeaderGap);
  ${DashboardTaskTitleStyle} {
    flex-grow: 1;
  }

  > ${ReadOnlyCheckboxStyle} {
    flex-shrink: 0;
  }
`;

export const TaskButtonBaseStyle = styled(Button).attrs({
  className: 'TaskButtonBaseStyle',
})`
  --taskHeaderGap: 0.75rem;
  color: ${({ theme }) => theme.Color.GrayLightestAccessible};
  background: white;
  a {
    box-shadow: 0px 0px 9px -3px ${({ theme }) => theme.line.color.default};
  }
  ${mobileScreen} {
    --taskHeaderGap: 0.5rem;
    a {
      box-shadow: none;
    }
    > * {
      border-bottom: none;
    }
  }
`;

const expandedTaskButtonCSS = css`
  color: ${({ theme }) => theme.Color.PointBlack};
  ${DashboardTaskTitleStyle} {
    font-weight: 600;
    text-overflow: unset;
    white-space: normal;
    max-height: 200px;
  }
  ${RowIconStyle} {
    &:last-child {
      path {
        fill: currentColor;
      }
    }
  }
  ${AccordionStyles.AccordionItemContainerStyle} {
    padding-left: calc(var(--rowIconSize) + var(--taskHeaderGap));
  }
  ${ButtonStyles.ButtonContentStyle} {
    gap: 1rem;
  }
  ${HrStyle} {
    opacity: 1;
    &:after {
      opacity: 1;
    }
  }
`;

export const TaskButtonUpcomingStyle = styled(TaskButtonBaseStyle).attrs({
  className: 'TaskButtonUpcomingStyle',
})<{ isExpanded?: boolean }>`
  --rowIconSize: 20px;
  --rightMargin: ${({ theme }) => theme.button.iconSize[Size.Default]}px;
  overflow: hidden;
  ${DashboardTaskTitleStyle} {
    transition: all 250ms ease-in-out;
    max-height: 20px;
  }
  ${RowIconStyle} {
    path {
      fill: ${({ theme }) => theme.line.color.default};
    }
    &:last-child {
      width: 18px;
      height: 18px;
    }
  }
  button {
    // TODO: fix this !important when compact version is integrated
    padding-right: var(--rightMargin) !important;
    ${desktopHoverCondition()} {
      &:hover,
      &:focus,
      &:active {
        border-color: ${({ theme }) => theme.Color.Gray3};
        color: ${({ theme }) => theme.Color.PointBlack};
        ${RowIconStyle} {
          path {
            fill: ${({ theme }) => theme.line.color.default};
          }
          &:last-child {
            path {
              fill: currentColor;
            }
          }
        }
      }
    }
  }
  ${AccordionStyles.AccordionItemHeaderStyle} {
    display: none;
  }
  ${AccordionStyles.AccordionItemStyle} {
    padding: 0;
  }
  ${ButtonStyles.ButtonContentStyle} {
    display: flex;
    flex-flow: column nowrap;
    transition: all 250ms ease-in-out;
    gap: 0;
  }
  ${HrStyle} {
    --arrowSize: 14px;
    position: relative;
    right: calc((var(--rightMargin) + var(--taskHeaderGap)) * -1);
    transition: all 250ms ease-in-out;
    opacity: 0;
    &:after {
      content: '';
      display: block;
      opacity: 0;
      border: 1px solid ${({ theme }) => theme.line.color.default};
      border-right: none;
      border-bottom: none;
      background: white;
      transform: rotate(45deg);
      position: absolute;
      right: calc(var(--rightMargin) + (var(--arrowSize) / 2) + (var(--rowIconSize) / 3));
      top: calc(var(--arrowSize) * -0.5);
      width: var(--arrowSize);
      height: var(--arrowSize);
    }
  }
  ${mobileScreen} {
    --rightMargin: ${({ theme }) => theme.button.iconSize[Size.Small]}px;
    ${RowIconStyle} {
      &:last-child {
        width: 16px;
        height: 16px;
        path {
          fill: currentColor;
        }
      }
    }
  }
  ${({ isExpanded }) => isExpanded && expandedTaskButtonCSS}
`;

export const TaskButtonSubmittedStyle = styled(TaskButtonBaseStyle).attrs({
  className: 'TaskButtonSubmittedStyle',
})`
  > * {
    --rightMargin: ${({ theme }) => theme.button.iconSize[Size.Default]}px;
    border-left: none;
    border-right: none;
    border-radius: 0;

    // TODO: fix this !important when compact version is integrated
    padding-right: var(--rightMargin) !important;
    ${mobileScreen} {
      --rightMargin: ${({ theme }) => theme.button.iconSize[Size.Small]}px;
    }
  }
`;

export const DateCompletedStyle = styled.span.attrs({
  className: 'DateCompletedStyle',
})`
  display: block;
  font-size: ${pxToRem(14)};
  color: ${({ theme }) => theme.Color.Gray3};
  flex-shrink: 0;
  ${mobileScreen} {
    display: none;
  }
`;
