import React, { useState } from 'react';
import {
  Color,
  DirectionAndPlacement,
  Header,
  Icon,
  IconName,
  Size,
  Style,
  directory,
} from '@pointdotcom/pds';
import { PlayIconStyle } from 'components/VideoPlayer/PlayButton';
import VideoPlayerModal from 'components/VideoPlayer/VideoPlayerModal';
import { LifeSaverIcon } from 'containers/DashboardPage/icons';
import { Page } from 'containers/helpers';
import { generateUrlFromPage } from 'containers/helpers';
import useLinkProps from 'containers/hooks/useLinkProps';
import DashboardModel from 'models/DashboardModel';
import { isPrequalEstimateId } from 'models/OfferEstimateModel';
import { SupportOptions } from '../SupportOptions';
import i18n from './i18n';
import {
  FundingResourcesSectionStyle,
  ItemTitleStyle,
  ResourceItemStyle,
  ResourceItemsStyle,
  SectionHeaderStyle,
} from './styles';

const iconSize = 20;

function VideoResource() {
  const [videoIsOpen, setVideoIsOpen] = useState<boolean>(false);

  return (
    <>
      <ResourceItemStyle as="button" onClick={() => setVideoIsOpen(true)}>
        <ItemTitleStyle>
          <Header styleSize={Size.Small} noMargin>
            {i18n.keyConcepts}
          </Header>
          <PlayIconStyle />
        </ItemTitleStyle>
        <span>{i18n.watchOur}</span>
      </ResourceItemStyle>
      <VideoPlayerModal
        isOpen={videoIsOpen}
        onClose={() => setVideoIsOpen(false)}
        bgClickToClose
        escToClose
        showX
      />
    </>
  );
}

function CostEstimatorResource({ dashboard }: { dashboard: DashboardModel }) {
  const linkProps = useLinkProps(generateUrlFromPage(Page.DASHBOARD_ESTIMATOR));

  if (
    dashboard.getDocket()?.getLatestOffer() == null &&
    !isPrequalEstimateId(dashboard.application?.estimateKey)
  ) {
    return null;
  }

  return (
    <ResourceItemStyle as="a" {...linkProps}>
      <ItemTitleStyle>
        <Header styleSize={Size.Small} noMargin>
          {i18n.exploreTheCost}
        </Header>
        <Icon name={IconName.ChevronRight} styleSize={iconSize} color={Color.Gray1Transparency} />
      </ItemTitleStyle>
      <span>{i18n.useThisTool}</span>
    </ResourceItemStyle>
  );
}

function HelpCenterResource() {
  const { PointDomain } = directory;
  const linkProps = useLinkProps(PointDomain.Help, { newTab: true });

  return (
    <ResourceItemStyle as="a" {...linkProps}>
      <ItemTitleStyle>
        <Header styleSize={Size.Small} noMargin>
          {i18n.haveQuestions}
        </Header>
        <Icon name={IconName.ChevronRight} styleSize={iconSize} color={Color.Gray1Transparency} />
      </ItemTitleStyle>
      <span>{i18n.visitOurHelp}</span>
    </ResourceItemStyle>
  );
}

interface FundingResourcesSectionProps {
  dashboard: DashboardModel;
}

export function FundingResourcesSection({ dashboard }: FundingResourcesSectionProps) {
  return (
    <FundingResourcesSectionStyle>
      <SectionHeaderStyle>
        <LifeSaverIcon />
        <Header styleType={Style.Uppercase} styleAlign={DirectionAndPlacement.Center} noMargin>
          {i18n.yourFunding}
        </Header>
      </SectionHeaderStyle>
      <ResourceItemsStyle>
        <VideoResource />
        <CostEstimatorResource dashboard={dashboard} />
        <HelpCenterResource />
      </ResourceItemsStyle>
      <SupportOptions accountManager={dashboard.getAccountManager()} />
    </FundingResourcesSectionStyle>
  );
}
