import React, { useRef } from 'react';
import {
  CloseButton,
  DirectionAndPlacement,
  Header,
  Icon,
  IconName,
  LinkButton,
  MarkText,
  SplashText,
  Style,
  TemplatedText,
  Tooltip,
  useIsMobile,
} from '@pointdotcom/pds';
import { useDashboardOfferAmount } from 'containers/hooks/useDashboardOfferAmount';
import DashboardModel from 'models/DashboardModel';
import { getDashboardEstFundDateTTOpen, setDashboardEstFundDateTTOpen } from 'store/general';
import { useDispatch, useSelector } from 'store/hooks';
import coinsImg from '../images/coins.png';
import i18n from './i18n';
import {
  ContentWrapperStyle,
  DateRangeButtonStyle,
  DateRangeContainerStyle,
  FundingDateStyle,
  OfferAmountSectionStyle,
  OfferAmountStyle,
} from './styles';

interface OfferAmountSectionProps {
  dashboard: DashboardModel;
}

export function OfferAmountSection({ dashboard }: OfferAmountSectionProps) {
  const { offerAmountFormatted } = useDashboardOfferAmount(dashboard);
  const ttRef = useRef<HTMLDivElement>(null);
  const isTooltipOpen = useSelector(getDashboardEstFundDateTTOpen);
  const dispatch = useDispatch();
  const { isMobile } = useIsMobile();

  return (
    <OfferAmountSectionStyle>
      <ContentWrapperStyle>
        <Header styleType={Style.Uppercase} noMargin>
          {i18n.yourInitial}
        </Header>
        <OfferAmountStyle>
          <SplashText noMargin>{offerAmountFormatted}</SplashText>
          <img src={coinsImg} alt="" />
        </OfferAmountStyle>
        <FundingDateStyle>
          <span>
            <TemplatedText
              values={{ nbsp: <>&nbsp;</>, estimated: <strong>{i18n.estimated}</strong> }}
            >
              {i18n.yourEstimated}
            </TemplatedText>
          </span>
          <DateRangeContainerStyle>
            <DateRangeButtonStyle
              ref={ttRef}
              onClick={() => dispatch(setDashboardEstFundDateTTOpen(!isTooltipOpen))}
              styleType={isTooltipOpen ? Style.Dark : Style.SubtleGray}
            >
              {dashboard.getFormattedFundingDateRange()}
              <Icon name={IconName.Info} />
            </DateRangeButtonStyle>
            <Tooltip
              xPos={DirectionAndPlacement.Right}
              yPos={isMobile ? DirectionAndPlacement.Bottom : DirectionAndPlacement.Center}
              isOpen={isTooltipOpen}
              positionRef={ttRef}
              onClose={() => dispatch(setDashboardEstFundDateTTOpen(false))}
              shadeBg
              inline
            >
              <CloseButton onClick={() => dispatch(setDashboardEstFundDateTTOpen(false))} />
              <SplashText as="div">
                <p>
                  <TemplatedText
                    values={{
                      thisIsAnEstimateOnlyHighlight: (
                        <MarkText>{i18n.thisIsAnEstimateOnlyHighlight}</MarkText>
                      ),
                    }}
                  >
                    {i18n.thisIsAnEstimateOnly}
                  </TemplatedText>
                </p>
                <p>
                  <TemplatedText>{i18n.asWeLearn}</TemplatedText>
                </p>
                <LinkButton onClick={() => dispatch(setDashboardEstFundDateTTOpen(false))}>
                  {i18n.close}
                </LinkButton>
              </SplashText>
            </Tooltip>
          </DateRangeContainerStyle>
        </FundingDateStyle>
      </ContentWrapperStyle>
    </OfferAmountSectionStyle>
  );
}
