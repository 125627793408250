import React, { useState } from 'react';
import dayjs from 'dayjs';
import {
  AccordionItem,
  Button,
  ButtonProps,
  DirectionAndPlacement,
  IconName,
  Style,
  TemplatedText,
} from '@pointdotcom/pds';
import { Hr } from 'components/HrVr';
import { Page, generateUrlFromPage } from 'containers/helpers';
import useLinkProps from 'containers/hooks/useLinkProps';
import { newlineToParagraph } from 'containers/tasks/utils';
import { Task, TaskStatus, TaskType } from 'services/apiTypes/taskTypes';
import i18n from './i18n';
import * as styles from './styles';

export function getTaskHref(task: Task): null | string {
  switch (task.type) {
    case TaskType.Application:
      return generateUrlFromPage(Page.HEI_APPLICATION, {
        estimateKey: task.detail.estimateKey,
      });
    case TaskType.DirectLink:
      return task.detail.href;
    case TaskType.DocumentUpload:
      return generateUrlFromPage(Page.DASHBOARD_TASK, { taskId: task.id });
    case TaskType.GenericDocumentFollowupQuestion:
    case TaskType.GenericTextFollowupQuestion:
      return generateUrlFromPage(Page.DASHBOARD_TASK, { taskId: task.id });
    case TaskType.QuizOrProductCall:
      return generateUrlFromPage(Page.HEI_EDUCATION);
    case TaskType.ReadOnly:
      return null;
    default:
      // For unrecognized tasks, use the fallback URL, if any
      return task.fallbackHref ?? null;
  }
}
interface DashboardTaskProps {
  task: Task;
  onClick?: (event: React.MouseEvent) => unknown;
  href?: null | string;
  className?: string;
  primaryCTA?: boolean;
  taskTitleFormatter?: (taskTitle: string) => React.ReactNode;
}

function DashboardTaskUpcoming({
  task,
  taskTitleFormatter = newlineToParagraph,
}: DashboardTaskProps) {
  const { taskDescription } = task;
  const [isExpanded, setIsExpanded] = useState(false);
  const handleClick = () => {
    setIsExpanded(!isExpanded);
  };

  // TODO: Replace with actual content
  const content =
    'It typically takes up to 5 business days to receive the report, after the appraisal has been completed. Once we receive the report, we will review it for accuracy within 2-3 business days and then share the report with you. ';

  return (
    <styles.TaskButtonUpcomingStyle
      styleType={Style.BorderedLink}
      onClick={handleClick}
      isExpanded={isExpanded}
    >
      <styles.DashboardTaskContentRowStyle>
        <styles.RowIconStyle name={IconName.Lock} />
        <styles.DashboardTaskTitleStyle>
          {taskTitleFormatter(taskDescription)}
        </styles.DashboardTaskTitleStyle>
        <styles.RowIconStyle name={IconName.Info} />
      </styles.DashboardTaskContentRowStyle>
      <Hr />
      <AccordionItem isExpanded={isExpanded} content={content} title={null} />
    </styles.TaskButtonUpcomingStyle>
  );
}

function DashboardTaskSubmitted({
  task,
  taskTitleFormatter = newlineToParagraph,
}: DashboardTaskProps) {
  const { taskDescription } = task;

  return (
    <styles.TaskButtonSubmittedStyle styleType={Style.BorderedLink} interactive={false}>
      <styles.DashboardTaskContentRowStyle>
        <styles.ReadOnlyCheckboxCheckedStyle />
        <styles.DashboardTaskTitleStyle>
          {taskTitleFormatter(taskDescription)}
        </styles.DashboardTaskTitleStyle>
        {task.submittedAt && (
          <styles.DateCompletedStyle>
            <TemplatedText values={{ date: dayjs(task.submittedAt).format('MMM D') }}>
              {i18n.completedOnDate}
            </TemplatedText>
          </styles.DateCompletedStyle>
        )}
      </styles.DashboardTaskContentRowStyle>
    </styles.TaskButtonSubmittedStyle>
  );
}

function DashboardTaskActive({
  task,
  onClick,
  taskTitleFormatter = newlineToParagraph,
  primaryCTA,
}: DashboardTaskProps) {
  const href = getTaskHref(task);
  const { taskDescription } = task;
  const linkProps = useLinkProps(href, { onClick });
  const interactive = !!href || !!onClick;
  const buttonProps: ButtonProps = {
    ...linkProps,
    interactive,
    iconType: interactive ? IconName.ChevronRight : undefined,
  };

  if (primaryCTA) {
    return (
      <Button
        {...buttonProps}
        styleType={Style.Primary}
        styleAlignText={DirectionAndPlacement.Left}
        block
      >
        <span>{taskTitleFormatter(taskDescription)}</span>
      </Button>
    );
  }

  return (
    <styles.TaskButtonBaseStyle
      interactive={!!href}
      styleType={Style.BorderedLink}
      {...buttonProps}
    >
      <styles.DashboardTaskContentRowStyle>
        <styles.ReadOnlyCheckboxStyle />
        <styles.DashboardTaskTitleStyle>
          {taskTitleFormatter(taskDescription)}
        </styles.DashboardTaskTitleStyle>
      </styles.DashboardTaskContentRowStyle>
    </styles.TaskButtonBaseStyle>
  );
}

export default function DashboardTask({
  task,
  onClick,
  primaryCTA = false,
  taskTitleFormatter = newlineToParagraph,
}: DashboardTaskProps) {
  const { status } = task;

  switch (status) {
    case TaskStatus.Active:
      return (
        <DashboardTaskActive
          task={task}
          onClick={onClick}
          taskTitleFormatter={taskTitleFormatter}
          primaryCTA={primaryCTA}
        />
      );
    case TaskStatus.Submitted:
      return <DashboardTaskSubmitted task={task} taskTitleFormatter={taskTitleFormatter} />;
    case TaskStatus.Upcoming:
      return <DashboardTaskUpcoming task={task} taskTitleFormatter={taskTitleFormatter} />;
    default:
      return null;
  }
}
