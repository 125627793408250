export default {
  completed: 'Completed',
  currentStage: 'Current Stage',
  upcoming: 'Upcoming',
  completedOnDate: 'Completed on %date%',
  estimatedDays: 'Estimated up to %minDays%-%maxDays% days',
  yourFundingTimeline: 'Your Funding Timeline',
  complete: 'Complete',
  inProgress: 'In Progress',
  notStarted: 'Not Started',
};
