import React from 'react';
import { CSSTransition } from 'react-transition-group';
import { DirectionAndPlacement, Header, Size, TemplatedText, useScrollSpy } from '@pointdotcom/pds';
import ChatlioButton from 'components/ChatlioButton';
import { ANIM_SPEED_MS } from 'components/ChatlioButton/styles';
import { FeatureFlag, useFeatureFlag } from 'lib/featureFlags';
import { DashboardAccountManager } from 'models/DashboardModel';
import i18n from './i18n';
import * as styles from './styles';

function Divider({ phrase }: { phrase: string }) {
  return (
    <styles.DividerStyle>
      <div />
      <span>{phrase}</span>
      <div />
    </styles.DividerStyle>
  );
}

export interface DashboardAccountManagerSectionProps {
  accountManager: DashboardAccountManager;
}

function ContactInfo({ accountManager }: DashboardAccountManagerSectionProps) {
  if (typeof accountManager !== 'object') return null;
  const { firstName, name, image, jobTitle, email, phoneNumber } = accountManager;

  return (
    <styles.AccountManagerSectionWrapper>
      <styles.AccountManagerSectionStyle>
        <styles.QuestionHeaderStyle styleSize={Size.Large} noMargin>
          {i18n.stillHaveQuestions}
          <br />
          <TemplatedText values={{ firstName }}>
            {firstName ? i18n.meetYourAccountManagerName : i18n.meetYourAccountManager}
          </TemplatedText>
        </styles.QuestionHeaderStyle>
        <styles.AccountManagerDetailStyle>
          {image ? <img src={image} alt="" /> : null}
          <styles.AccountManagerContactInfoStyle>
            <div>
              <Header noMargin>{name}</Header>
              <span>{jobTitle}</span>
            </div>
            <styles.ContactMethodsStyle>
              <a href={`mailto:${email}`}>{email}</a>
              <a href={`tel:${phoneNumber?.replace(/\s/g, '')}`}>{phoneNumber}</a>
            </styles.ContactMethodsStyle>
          </styles.AccountManagerContactInfoStyle>
        </styles.AccountManagerDetailStyle>
      </styles.AccountManagerSectionStyle>
    </styles.AccountManagerSectionWrapper>
  );
}

function ContactInfoWithChat({ accountManager }: DashboardAccountManagerSectionProps) {
  const staticChatBtnRef = React.useRef<HTMLDivElement>(null);
  const { spyIsShown } = useScrollSpy({ spyOn: staticChatBtnRef });

  if (typeof accountManager !== 'object') return null;
  const { firstName, image, email, phoneNumber } = accountManager;

  return (
    <styles.AccountManagerSectionWrapper>
      <styles.AccountManagerSectionStyle>
        <styles.QuestionHeaderStyle
          styleSize={Size.Large}
          noMargin
          shortHeadline
          styleAlignMobile={DirectionAndPlacement.Center}
        >
          {i18n.stillHaveQuestions}
        </styles.QuestionHeaderStyle>
        <styles.ChatWithAccountManagerStyle id="chatButton">
          <ChatlioButton ref={staticChatBtnRef} mode="static-dark" />
          <Divider phrase="or" />
          <styles.ReducedAccountManagerDetailStyle>
            {image ? <img src={image} alt="" /> : null}
            <styles.AccountManagerContactInfoStyle>
              <span>
                <TemplatedText values={{ firstName }}>
                  {firstName ? i18n.reachOutToName : i18n.reachOutTo}
                </TemplatedText>
              </span>
              <styles.ContactMethodsDividedStyle>
                <a href={`mailto:${email}`}>{email}</a>
                <div />
                <a href={`tel:${phoneNumber?.replace(/\s/g, '')}`}>{phoneNumber}</a>
              </styles.ContactMethodsDividedStyle>
            </styles.AccountManagerContactInfoStyle>
          </styles.ReducedAccountManagerDetailStyle>
        </styles.ChatWithAccountManagerStyle>
      </styles.AccountManagerSectionStyle>

      <CSSTransition classNames="slideout" in={spyIsShown} timeout={ANIM_SPEED_MS}>
        <ChatlioButton mode="floating" />
      </CSSTransition>
    </styles.AccountManagerSectionWrapper>
  );
}

export default function DashboardAccountManagerSection({
  accountManager,
}: DashboardAccountManagerSectionProps) {
  const dashboardAmChatFlagEnabled = useFeatureFlag(FeatureFlag.DashboardAmChat) === 'test';
  const chatOnline = window._chatlio?.isOnline && window._chatlio?.isOnline();

  return dashboardAmChatFlagEnabled && chatOnline ? (
    <ContactInfoWithChat accountManager={accountManager} />
  ) : (
    <ContactInfo accountManager={accountManager} />
  );
}
